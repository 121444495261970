* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* direction: rtl; */
  color: #fff;
  border: none;
}

body {
  width: 100%;
  min-height: 100vh;
  background-color: #222;
  direction: rtl;
}

div#root {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #222;
}

div#App {
  position: relative;
  width: 100%;
  min-height: 100vh;
}

input {
  border: none;
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

button {
  outline: none;
  border: none;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}
