@font-face {
  font-family: "Roboto Slab";
  src: url("styles/RobotoSlab-Light.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "IRANSans";
  src: url("styles/IRANSans-web.ttf") format("truetype");
  font-style: normal;
}

$persian-font: "IRANSans", serif;
$latin-font: "Roboto Slab", serif;
$pink-gradient: #ff4685;
$blue-gradient: #2196f3;
$main-dark: #222;
$linear-gradient: linear-gradient(45deg, $pink-gradient, $blue-gradient);
$linear-gradient-reverse: linear-gradient(
  45deg,
  $blue-gradient,
  $pink-gradient
);
$linear-transition: all 0.55s ease-in-out;

div#App {
  display: flex;
  justify-content: center;
  font-family: $persian-font;
  gap: 40px;
  padding: 30px 0;
  h1 {
    position: relative;
    font-family: $latin-font;
    width: 100%;
    text-align: center;
    font-size: 38px;
  }
  .main-body {
    font-family: $latin-font;
    position: relative;
    width: calc(100% - 20px);
    max-width: 1600px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    // border: 1px solid red;
    @media (max-width: 996px) {
      flex-direction: column;
      align-items: center;
    }
    .search-box {
      max-width: 640px;
      min-width: 360px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding: 0 10px;
      @media (max-width: 996px) {
        width: 100%;
        padding: 0 20px;
        .vocabulary {
          flex-direction: row;
        }
      }
      // background-color: antiquewhite;
      .input-box {
        position: relative;
        width: 100%;
        max-width: 600px;
        max-height: 80px;
        input {
          font-family: $latin-font;
          font-size: 22px;
          direction: ltr;
          letter-spacing: 0.07em;
          position: relative;
          width: 100%;
          height: 100%;
          color: $main-dark;
          font-weight: 900;
          padding: 10px 20px;
          z-index: 2;
          background-color: transparent;
          border-radius: 10px;
          box-shadow: 0 0px 30px rgba(255, 255, 255, 0.1);
          //   background-color: wheat;
          &:valid,
          &:focus {
            border-radius: 10px 10px 3px 3px;
          }
          &:valid ~ span,
          &:focus ~ span {
            transform: translateX(-40px);
            left: 10px;
            color: transparent;
          }
          &:valid ~ i,
          &:focus ~ i {
            height: 100%;
            background: $linear-gradient;
          }
          &:valid ~ button,
          &:focus ~ button {
            z-index: 2;
          }
        }
        button {
          position: absolute;
          top: 10px;
          right: 10px;
          background-color: transparent;
          cursor: pointer;
          z-index: 1;
          i {
            color: $pink-gradient;
            font-size: 1.25em;
            cursor: pointer;
          }
        }
        span {
          position: absolute;
          top: 10px;
          left: 30px;
          color: $pink-gradient;
          transition: $linear-transition;
          pointer-events: none;
          color: $main-dark;
        }
      }
      i.input-border {
        position: absolute;
        border-radius: 10px;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background: $linear-gradient;
        z-index: 1;
        pointer-events: none;
        transition: $linear-transition;
      }
      .vocabulary {
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 10px;
        direction: ltr;
        div {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 20px;
          &.vocab-title {
            justify-content: center;
            h2 {
              color: $blue-gradient;
              font-weight: 900;
            }
          }
          &.vocab-pronounciation {
            justify-content: space-evenly;
          }
          &.vocab-sounds {
            justify-content: center;
            gap: 50px;
            img {
              width: 54px;
              height: 50px;
              &:last-child {
                margin-left: 20px;
              }
            }
          }
        }
        span.academic {
          position: relative;
          padding: 2px 5px 4px;
          background-color: $blue-gradient;
          border-radius: 5px;
          align-self: center;
        }
        span#vocab-score {
          padding-left: 20px;
          font-size: 18px;
        }
        strong#word {
          color: $blue-gradient;
          font-size: 16px;
          padding-left: 20px;
        }
        span#message {
          font-size: 14px;
        }
      }
    }
    .vocab-box {
      direction: ltr;
      position: relative;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      width: 100%;
      max-width: 1200px;
      padding: 0px 10px;
      color: $blue-gradient;
      transition: all 1.55s ease-in-out;

      .examples {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        h3 {
          position: absolute;
          background-color: $blue-gradient;
          padding: 5px 10px;
          border-radius: 8px;
          cursor: pointer;
          left: 50%;
          transform: translate(-50%, 50%);
          transition: all 0.55s ease-in-out;
        }
        .sentences {
          width: 100%;
          min-height: 420px;
          box-shadow: 0 5px 25px rgba(255, 255, 255, 0.1);
          overflow-y: scroll;
          background-color: transparent;
          padding: 10px 15px;
          border-radius: 10px;
          scrollbar-width: none;
          -ms-overflow-style: none;
          &::-webkit-scrollbar {
            display: none;
          }
          .setntence {
            background-color: transparent;
            width: 100%;
            padding: 10px 0;
            direction: ltr;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-bottom: 1px solid $pink-gradient;
            &:last-child {
              border-bottom: none;
            }
            p {
              letter-spacing: 0.05em;
              text-align: justify;
            }
            a {
              font-size: 12px;
              padding: 1px 5px;
              background-color: $blue-gradient;
              border-radius: 5px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
